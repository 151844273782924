 
.rdt_TableCell {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400; 
  line-height: 1.43;
  text-rendering: optimizeLegibility;
}

.rdt_TableCol{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  font-size: 0.775rem;
  font-weight: 700; 
  line-height: 1.43;
  text-rendering: optimizeLegibility;
}
